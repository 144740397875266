import * as React from 'react'
import styled from '@emotion/styled'

import { mq } from '../styles/variables'

const StyledLayoutLegal = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px;
  line-height: 2em;

  ${mq('md')} {
    padding: 100px;
  }
`

interface LayoutMainProps {
  className?: string
}

const LayoutLegal: React.FC<LayoutMainProps> = ({ children, className }) => (
  <StyledLayoutLegal className={className}>{children}</StyledLayoutLegal>
)

export default LayoutLegal
