import * as React from 'react'
import { ThemeProvider } from 'emotion-theming'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'

import 'modern-normalize'
import '../styles/normalize'

import LayoutRoot from '../components/LayoutRoot'
import LayoutLegal from '../components/LayoutLegal'

import LogoSvg from '../images/logo.svg'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
}

const theme = {
  breakpoints: ['576px', '768px', '992px', '1200px', '1600px'],
  space: [0, 4, 8, 16, 32, 64, 128, 256]
}

const LegalLayout: React.FC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LegalLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <ThemeProvider theme={theme}>
        <LayoutRoot>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: data.site.siteMetadata.description },
              { name: 'keywords', content: data.site.siteMetadata.keywords }
            ]}
          />

          <LayoutLegal>
            {' '}
            <a
              css={css`
                margin-bottom: 50px;
                margin-right: 30px;
                max-width: 320px;
              `}
              href="/"
            >
              <LogoSvg width="100%" />
            </a>
            {children}
          </LayoutLegal>
        </LayoutRoot>
      </ThemeProvider>
    )}
  />
)

export default LegalLayout
